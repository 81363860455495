import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`객체 - new.target`}</h3>
    <p>{`ES2015에서 도입된 `}<inlineCode parentName="p">{`new.target`}</inlineCode>{` 문법은 생성자 내부에서 사용됩니다. 만약 생성자가 `}<inlineCode parentName="p">{`new`}</inlineCode>{`를 통해 호출되면 `}<inlineCode parentName="p">{`new.target`}</inlineCode>{`에는 해당 생성자가 저장됩니다. 만약 생성자가 일반적인 함수로서 호출되면, `}<inlineCode parentName="p">{`new.target`}</inlineCode>{`에는 `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`가 저장됩니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Person() {
  if (new.target) {
    console.log('생성자로 호출되었습니다.');
  } else {
    console.log('생성자로 호출되지 않았습니다.');
  }
}

new Person(); // 생성자로 호출되었습니다.
Person(); // 생성자로 호출되지 않았습니다.
`}</code></pre>
    <p>{`이 기능을 이용해, 실수로 `}<inlineCode parentName="p">{`new`}</inlineCode>{`를 빠트렸을 때도 문제없이 객체가 생성되도록 코드를 작성할 수 있습니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Person(name) {
  if (!new.target) {
    // \`new\` 없이 호출되면, 직접 객체를 생성해 반환합니다.
    return new Person(name);
  } else {
    this.name = name;
  }
}
`}</code></pre>
    <h3>{`객체 - instanceof`}</h3>
    <p>{`앞에서 봤던 `}<inlineCode parentName="p">{`instanceof`}</inlineCode>{` 연산자의 동작은 사실 생각보다 조금 복잡합니다. `}<inlineCode parentName="p">{`instanceof`}</inlineCode>{` 연산자는 `}<strong parentName="p">{`생성자의 `}<inlineCode parentName="strong">{`prototype`}</inlineCode>{` 속성`}</strong>{`이 `}<strong parentName="p">{`객체의 프로토타입 체인에 등장하는지`}</strong>{`를 검사합니다. 그래서, 특별한 경우가 아니라면 생성자를 통해 생성된 객체는 `}<inlineCode parentName="p">{`Object`}</inlineCode>{` 생성자의 인스턴스이기도 합니다.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Person() {}
const person = new Person();
person1 instanceof Person; // true
person instanceof Object; // true
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      